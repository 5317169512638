<template>
    <div>
    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="pageNum"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="companyName" align="center" label="公司名称"></el-table-column>
        <el-table-column prop="title" align="center" label="产品名称"></el-table-column>
        <el-table-column prop="createTime" align="center" label="扫码时间"></el-table-column>
        <el-table-column prop="handle" align="center" label="标识"></el-table-column>
        <el-table-column prop="integral" align="center" label="积分"></el-table-column>
      </template>
    </commonTable>
    </div>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import { get, post } from "@/utils/request";
export default {
  name: "vipManage",
  components: {
    commonTable,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      userId: null,
    }
  },
  created() {
      this.userId = this.$route.query.userId
      this.getTableData()
  },
  methods: {
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.getTableData();
    },
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    getTableData() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: this.userId,
        companyId : JSON.parse(localStorage.getItem('info')).companyId
      }
      get("/integralRecord/integralRecordList", params).then((res) => {
        this.tableData = res.list;
        this.total = res.total
      });
    },
    

  }
}
</script>
<style scoped>
.avatar{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
</style>